@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    font-family: 'Roboto', sans-serif;
}
.containerAlarme {
    position: relative;
    height: 100vh;
    width: 100vw;
}

.row h1 {
    color:black;
    font-size: 20px;
    font-weight: 700;
}
.row tbody a :hover{
    background-color:red;
    cursor: pointer;
}
.row Table {
    color:black;
    border-color: black !important;
}
.card {
-webkit-box-shadow: 9px 8px 30px 6px rgba(0,0,0,0.7); 
box-shadow: 9px 10px 15px 1px rgba(0,0,0,0.7);
border: 1px solid rgba(0,0,0,0.6);
border-radius: 10px;

}
.row Table thead {
    background-color: #F2F2F2;
}
.table-bordered td, .table-bordered th {
    border: 1px solid rgba(0,0,0,0.5) !important;
}