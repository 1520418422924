.containerAlarme {
    position: relative;
    height: 100vh;
    width: 100vw;
}

.row h1 {
    color:black;
    font-size: 20px;
    font-weight: 700;
}
.row Table tbody  :hover{
    background-color:red;
    cursor: pointer;
}
.row Table {
    color:black;
}
.col{
    padding: 20px;
}

.spinner-grow {
    display: inline-block;
    width: 15rem;
    height: 15rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite;
}