@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=The+Nautigal:wght@700&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

body {
  font-family: Arial, Helvetica, sans-serif;

}


.custom-dropdown Button {
  background-color: transparent;
  color: black;
  border-style: none;
}

.custom-dropdown .btn-secondary:hover,
.custom-dropdown .btn-secondary:active,
.custom-dropdown .btn-secondary:focus {

  background-color: transparent !important;
  box-shadow: 0 5px 0 0 #127FBF !important;
  color: black !important;
  border-style: none !important;
  border-radius: 0px ! important;
  z-index: -2;
}

.sb-avatar,
.sb-avatar__text {
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
}

#root>div.container-fluid>div>hr {
  width: 90%;
  border-radius: 10px;
  color: black !important;
  margin-inline: auto;
}

#dropdown-button-drop-end {
  margin-left: 3% !important;
}

.avatar {
  display: flex;
  align-items: flex-end;
}

/* .navbar {
    overflow: hidden;
    background-color: #fff;
  }
  
  .navbar a {
    float: left;
    font-size: 16px;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-weight: 600;
  }
  
  .dropdown {
    float:left;
    overflow: hidden;
    
  }
  
  .dropdown .dropbtn {
    font-size: 16px;  
    border: none;
    outline: none;
    color: black;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    font-weight: 600;
  }
  
  .navbar a:hover, .dropdown:hover .dropbtn {
    text-decoration: double underline;
  }
  
  .dropdown-content {
    display: none;
    position: fixed;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  hr {
    width:95vw;
    border-radius: 100%;
    margin-top: 0px;
  }
  .logo{
    width: 25%;
    margin-top: -10px;
  } */

.buttonsMenu {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 1%;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 5%;
  margin-right: 2%;
  width: 10%;
  display: none;
}
.buttonsMenu:hover {
  display: block !important;
}
.buttonLinks:hover + .buttonsMenu {
  display: block !important;
}
.buttonLinks > .btn {
  border-radius: 50% !important;
  width: 80px;
  height: 80px;
}
.buttonLinks {
  bottom: 0;
  right: 0;
  margin-right: 2%;
  margin-bottom: 2%;
  position: fixed;
}

.buttonsMenu button {
  width: 200px;
  height: 50px;
  border-radius: 7px;
  -webkit-box-shadow: 10px 10px 17px -1px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 10px 10px 17px -1px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 10px 10px 17px -1px rgba(0, 0, 0, 0.75) !important;
}

.ButtonLogoMk {
  background-image: url('./imagens/mk.png') !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
}

.ButtonLogoWebmail {
  background-image: url('./imagens/webmail.png') !important;
  background-size: cover;
  background-position-x: center;
}

.ButtonLogoBI {
  background-image: url('./imagens/BI.png') !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
}

.ButtonLogoCoud {
  background-image: url('./imagens/nextcloud.png') !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
}
.ButtonLogoZoho {
  background-image: url('./imagens/zoho.png') !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;

}
.ButtonLogoBot {
  background-image: url('./imagens/multichannel.png') !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;

}
.ButtonLogoTelefonia {
  background-image: url('./imagens/telefonia.png') !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;

}
.ButtonLogoMovel {
  background-image: url('./imagens/movel.png') !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;

}